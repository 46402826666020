<template>
  <div class="offer-card">
    <div class="offer-card__label">{{ $t("af:selected_offers.title") }}</div>

    <div class="offer-card__row py-4">
      <b-skeleton v-if="loading" width="80%"></b-skeleton>

      <div v-else class="px-2">
        <PartnerLogo
          :partner-logo-prop="offer.partnerLogo"
          :partner-name-prop="offer.partnerName"
          hide-name
        />
        <p class="has-text-weight-bold mt-2">
          {{ offer.name }}
        </p>
      </div>
    </div>

    <div class="offer-details--desktop offer-card__row py-4">
      <b-skeleton v-if="loading" height="53"></b-skeleton>

      <template v-else>
        <div class="info">
          <p class="is-size-7 is-uppercase has-text-grey-icon af-label">
            {{ $t("af:selected_offers.label.thm") }}
          </p>
          <p class="has-text-weight-bold">
            {{ thm }}{{ $t("af:selected_offers.label.thm_unit") }}
          </p>
        </div>
        <div class="info">
          <p class="is-size-7 is-uppercase has-text-grey-icon af-label">
            {{ $t("af:selected_offers.label.amount") }}
          </p>
          <p class="has-text-weight-bold">{{ amount }} Ft</p>
        </div>
        <div class="info">
          <p class="is-size-7 is-uppercase has-text-grey-icon af-label">
            {{ $t("af:selected_offers.label.duration") }}
          </p>
          <p class="has-text-weight-bold">
            {{ term }} {{ $t("af:selected_offers.label.duration_unit") }}
          </p>
        </div>
      </template>
    </div>

    <div class="offer-card__row">
      <p class="offer-change--desktop">
        <b-icon
          icon-pack="fa"
          icon="info-circle"
          size="is-medium"
          type="is-blue"
        ></b-icon>
        <span class="has-text-grey-icon ml-1">{{
          $t("af:selected_offers.footer")
        }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { formatSeconds, monify } from "@/utils/util";

export default {
  name: "SelectedOffer",
  components: {
    PartnerLogo: () => import("@/components/PartnerLogo.vue"),
  },
  data() {
    return {
      offer: null,
      loading: false,
    };
  },
  computed: {
    offerId() {
      return this.$route.query.offer;
    },
    thm() {
      return this.$route.query.thm;
    },
    amount() {
      return monify(this.$route.query.amount);
    },
    term() {
      return this.$route.query.term;
    },
  },
  async created() {
    this.loading = true;

    await this.fetchOfferPreview(this.offerId);
    this.offer = this.$store.getters["offers/offerPreview"];

    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  methods: {
    ...mapActions({
      fetchOfferPreview: "offers/fetchOfferPreview",
    }),
    formatSeconds,
  },
};
</script>

<style scoped lang="scss">
.offer-card {
  background: white;
  border-radius: 16px;
  padding: 12px;
  width: 90%;
  position: relative;

  &__label {
    position: absolute;
    top: -20px;
    left: 16px;
    font-size: 10px;
    color: white;
    background: #051c3a;
    padding: 4px 8px;
    border-radius: 4px 4px 0 0;
  }

  .partner-logo {
    max-height: 20px;
  }

  .af-label {
    margin-bottom: 6px;
  }

  .offer-info {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
    border-top: 1px solid #f3f5f9;
    border-bottom: 1px solid #f3f5f9;
    padding-top: 10px;
    padding-bottom: 10px;

    .icon {
      color: inherit;
    }

    .is-online-offer {
      color: #00b67f;
      font-weight: 700;
    }
  }

  .offer-details--desktop {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;

    div.info {
      padding: 6px 8px;

      p:nth-of-type(2) {
        font-size: 18px;
        font-weight: 500;
      }
    }

    .thm {
      background: #f3f5f9;
      border-radius: 4px;
    }
  }

  .offer-change--desktop {
    display: flex;
    align-items: center;
    text-align: center;
  }
}

@media only screen and (min-width: 769px) {
  .offer-card {
    padding: 32px 0;
    height: 100%;
    width: 100%;

    &__label {
      font-size: 12px;
      top: -22px;
    }

    &__row {
      padding: 0 36px;
    }

    .offer-info {
      display: flex;
    }

    .offer-details--desktop {
      display: flex;
    }

    .offer-change--desktop {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-bottom: 0;
      margin-top: 1rem;
      border-top: 1px solid #dfe6ef;
      padding-top: 8px;
    }
  }
}

.mbh {
  .offer-change--desktop {
    background: #e6e9f0;
    padding: 16px 24px;
    border-radius: 24px;
    font-size: 13px;

    .has-text-grey-icon {
      color: #203667 !important;
    }
  }

  .offer-card {
    border-radius: 32px;

    &__label {
      left: 32px;
      top: -24px;
      background-color: #203667;
      font-size: 13px;
    }
  }

  .has-text-weight-bold {
    font-weight: 700 !important;
    font-size: 20px;
  }
}
</style>
